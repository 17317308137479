<script setup>
import NotificationHelpDialog from '@/components/clients/NotificationHelpDialog.vue';
import useClients from '@/hooks/useClients';
import ClientLayout from '@/layouts/ClientLayout.vue';
import { validateEmail } from '@/utils/dataUtils';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

const { client, fetchClient, toggleNotifications } = useClients();
const route = useRoute();
const toast = useToast();
const showDialog = ref(false);
const email = ref("");
const allNotificationsList = ["prod_threshold","in_created_email","in_approved_email", "in_arrived_email", 
                            "in_received_email", "in_processed_email", "in_files_email", "in_messages_email", "out_created_email", 
                            "out_approved_email", "out_staged_email", "out_shipped_email", "out_delivered_email", "out_files_email", 
                            "out_messages_email", "so_created_email", "so_scheduled_email", "so_warehouse_email", "so_truck_email", 
                            "so_shipped_email", "so_delivered_email", "so_files_email", "so_messages_email"];

onMounted(async () => {
    await fetchClient(route.params.id);
})

const addEmail = async() => {
    if (!validateEmail(email.value)) {
        toast.error("Please enter a valid email address.");
        return;
    }

    let notifications = client.value.notifications.map(n => ({email: n.email, notifications: n.notifications}));
    notifications.push({email: email.value, notifications: [""]});
    const response = await toggleNotifications(route.params.id, { notifications });
    if (response) {
        toast.success("Email added successfully.");
        email.value = "";
    }
    await fetchClient(route.params.id);
}

const deleteNotification = async(email) => {
    let notifications = client.value.notifications.filter(n => n.email !== email);
    console.log(notifications)
    const response = await toggleNotifications(route.params.id, { notifications });
    if (response) {
        toast.success("Notification deleted successfully.");
    }
    await fetchClient(route.params.id);
}

const saveNotifications = async () => {
    let notifications = client.value.notifications.map(n => ({email: n.email, notifications: n.notifications}));
    const response = await toggleNotifications(route.params.id, { notifications });
    if (response) {
        toast.success("Notifications saved.");
    }
    await fetchClient(route.params.id);
}

</script>

<template>
    <ClientLayout :client="client">
        <div class="mx-4 mt-2">
            <div class="text-h4 mb-2">Notifications
                <v-btn icon="mdi-help-circle-outline" variant="text" class="ml-3" @click="() => showDialog = true"></v-btn>
            </div>
            <v-table class="rounded-sm">
                <thead class="bg-surface-light">
                    <tr>
                        <th rowspan="2" colspan="2" valign="bottom" class="text-center">Email/User</th>
                        <th colspan="1" class="text-center">Products</th>
                        <th colspan="7" class="text-center">Inbound</th>
                        <th colspan="7" class="text-center">Outbound</th>
                        <th colspan="8" class="text-center">Special Orders</th>
                    </tr>
                    <tr>
                        <th><div class="vertical">Threshold Alert</div></th>
                        <th><div class="vertical">Created</div></th>
                        <th><div class="vertical">Approved</div></th>
                        <th><div class="vertical">Arrived</div></th>
                        <th><div class="vertical">Received</div></th>
                        <th><div class="vertical">Processed</div></th>
                        <th><div class="vertical">File Uploaded</div></th>
                        <th><div class="vertical">Messages</div></th>
                        <th><div class="vertical">Created</div></th>
                        <th><div class="vertical">Approved</div></th>
                        <th><div class="vertical">Staged</div></th>
                        <th><div class="vertical">Shipped</div></th>
                        <th><div class="vertical">Delivered</div></th>
                        <th><div class="vertical">File Uploaded</div></th>
                        <th><div class="vertical">Messages</div></th>
                        <th><div class="vertical">Created</div></th>
                        <th><div class="vertical">Scheduled</div></th>
                        <th><div class="vertical">At Warehouse</div></th>
                        <th><div class="vertical">On Truck</div></th>
                        <th><div class="vertical">Shipped</div></th>
                        <th><div class="vertical">Delivered</div></th>
                        <th><div class="vertical">File Uploaded</div></th>
                        <th><div class="vertical">Messages</div></th>
                    </tr> 
                </thead>

                <tbody v-if="client?.notifications.length == 0">
                    <tr>
                        <td colspan="25" class="text-center">No notifications yet</td>
                    </tr>
                    <tr>
                        <td colspan="25" style="border-bottom: 1px solid rgb(108, 106, 106); height: 0px; line-height: 0px;"></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <template v-for="(notification, index) in client?.notifications">
                    <tr>
                        <td colspan="25" class="text-left">
                            <strong class="ml-2">{{notification.email}}</strong>
                            <v-btn @click="deleteNotification(notification.email)" icon="mdi-delete" variant="text" class="ml-5"></v-btn>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="text-right">E-mail</td>
                        <td v-for="notificationValue in allNotificationsList"> 
                            <v-checkbox 
                                density="compact" 
                                v-model="notification.notifications" 
                                @update:model-value="saveNotifications()" 
                                :value="notificationValue" 
                                hide-details 
                                color="primary" 
                            />
                        </td>
                    </tr>
                    <tr v-if="client?.notifications.length - 1 == index">
                        <td colspan="25" style="border-bottom: 1px solid rgb(108, 106, 106); height: 0px; line-height: 0px;"></td>
                    </tr>
                </template>
                </tbody>

            </v-table>

            <div class="d-flex justify-center align-center mt-5 ga-3 w-50 mx-auto">
                <v-text-field
                    type="email"
                    placeholder="Enter E-mail"
                    density="compact"
                    variant="outlined"
                    hide-details
                    v-model="email"
                />
                
                <v-btn 
                    variant="flat" 
                    color="primary"
                    @click="addEmail"
                    >
                    Add
                </v-btn>
            </div>
        </div>

        <NotificationHelpDialog v-if="showDialog" :callback="() => {showDialog = !showDialog}"/>
    </ClientLayout>
</template>

<style scoped>
.text-h4 {
    /*needs to be inside scope different font used in other components */
    font-family: 'Source Sans 3', 'sans-serif' !important;
}

.vertical {
    text-align:center;
    white-space:nowrap;
    writing-mode: vertical-rl;
    transform: rotate(360deg);
    padding: 5px 0;
}

th, td {
    text-align: center;
    padding: 0 5px !important;
}

.v-table th, .v-table td {
    border: 1px solid rgb(108, 106, 106);
}
</style>