<script setup>
import { ref } from "vue";

const showNotificationDialog = ref(true);

const props = defineProps({
    callback: Function,
});

const notificationTypes = [
    {
        name: "E-mail",
        description: "An e-mail notification sent to the user. Please add notifications@lmconnect.app to ensure you receive all notifications." 
    },
    {
        name: "Connect Web",
        description: "These are the notifications you see in Connect when you are logged in, or click the notification bell icon." 
    },
    {
        name: "Mobile App",
        description: "Push notifications are sent to you if you have the mobile app installed." 
    }
]

const inboundTriggers = [
    {
        name: "Created",
        description: "Order is created by your team or L&M" 
    },
    {
        name: "Approved",
        description: "Created order is approved/acknowledged by L&M" 
    },
    {
        name: "Arrived",
        description: "Order has arrived at L&M" 
    },
    {
        name: "Received",
        description: "Order has been checked for accuracy" 
    },
    {
        name: "Processed",
        description: "Order has been processed and is availabe in inventory" 
    },
    {
        name: "Messages",
        description: "Message is added to an order" 
    },
]

const outboundTriggers = [
    {
        name: "Created",
        description: "Order is created by your team or L&M" 
    },
    {
        name: "Approved",
        description: "Created order is approved/acknowledged by L&M" 
    },
    {
        name: "Staged",
        description: "Order has been picked & is being prepped for shipping" 
    },
    {
        name: "Shipped",
        description: "Order has left the L&M facility" 
    },
    {
        name: "Delivered",
        description: "Order has reached its destination" 
    },
    {
        name: "Messages",
        description: "Message is added to an order" 
    },
]
</script>

<template>
    <div class="text-center pa-4">
        <v-dialog v-model="showNotificationDialog" width="auto" persistent>
            <v-card>
                <v-toolbar>

                    <v-toolbar-title>Notification Information</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-items>
                        <v-btn icon="mdi-close" @click="callback()"></v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-container class="mt-4 px-4">
                <v-row>
                    <v-col cols="4">
                        <v-card>
                            <v-card-title class="bg-surface-light">Notifications Types</v-card-title>
                            <v-card-item >
                                <div v-for="notif in notificationTypes" class="mb-2">
                                    <div class="text-h6">{{notif.name}}</div>
                                    <div>{{notif.description}}</div>
                                </div>
                            </v-card-item>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card>
                            <v-card-title class="bg-surface-light">Inbound Triggers</v-card-title>
                            <v-card-item>
                                <v-row v-for="inbound in inboundTriggers">
                                    <v-col cols="4"class="text-h6">{{inbound.name}}</v-col>
                                    <v-col cols="8">{{inbound.description}}</v-col>
                                    <v-divider />
                                </v-row>
                            </v-card-item>
                        </v-card>
                    </v-col>

                    <v-col cols="4">
                        <v-card>
                            <v-card-title class="bg-surface-light">Outbound Triggers</v-card-title>
                            <v-card-item>
                                <v-row v-for="outbound in outboundTriggers">
                                    <v-col cols="4"class="text-h6">{{outbound.name}}</v-col>
                                    <v-col cols="8">{{outbound.description}}</v-col>
                                    <v-divider />
                                </v-row>
                            </v-card-item>
                        </v-card>
                    </v-col>
                </v-row>
                </v-container>

                <v-card-actions class="border-t-sm my-2 d-flex justify-end">
                    <v-btn
                        class="text-none"
                        color="primary"
                        text="Close"
                        variant="flat"
                        @click="callback()"
                    ></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style scoped>

.text-h6 {
    /*needs to be inside scope different font used in other components */
    font-family: 'Source Sans 3', 'sans-serif' !important;
    font-size: 1rem !important;
    font-weight: bold;
}
</style>